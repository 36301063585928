<template>
  <div v-show="hitMusicaAtual && activeMusic">

    <div class="player">
      <div class="player__inner">

        <div class="player__center">

          <!-- Player Controls -->
          <div class="player-controls">

            <b-icon
              size="20"
              class="player-controls__button icon-previous"
              cursor="pointer"
              icon="skip-backward"
              @click="prevMusic"
            />

            <div @click="playToggleBtn">
              <b-icon
                v-if="getPlayingStatus"
                size="30"
                icon="pause-circle"
                cursor="pointer"
                class="player-controls__button player-controls__button--pause"
              />

              <b-icon
                v-else
                size="30"
                icon="play-circle"
                cursor="pointer"
                class="player-controls__button player-controls__button--play"
              />

            </div>

            <b-icon
              size="20"
              class="player-controls__button icon-next"
              cursor="pointer"
              icon="skip-forward"
              @click="nextMusic"
            />

          </div>
          <!-- Player Controls -->

          
        </div>

        <div class="player__right">

          <div v-if="hitMusicaAtual && getPlayingStatus" class="hit-musica-atual text-white small">
            <h5
              class="current-track__name"
            >
              <b-icon
                font-size="12"
                icon="music-note-list"
                color="#FFF"
              />
              {{ hitMusicaAtual }}
            </h5>
          </div>

          <!-- Player Playback -->
          <div class="player-playback">
            <input
              ref="music_range"
              type="range"
              value="0"
              class="music-range"
              style="display: none;"
            >
            <div class="player-playback__time">
              {{ getCurrentTime(currentTime) }}
            </div>
            <div class="player-playback__progress-bar">
              <vue-slider
                v-model="barra"
                :max="100"
                tooltip="none"
                :dot-size="12"
                :process-style="{ background: '#fe9856' }"
                :rail-style="{ background: 'rgb(115, 117, 117)' }"
                :style="{padding: '6.5px'}"
                @drag-start="onDragStart"
                @callback="onProgressChange"
                @drag-end="onDragEnd"
                @change="onProgressChange"
              />
            </div>
            <div class=" player-playback__time">
              {{ getCurrentTime(durationTime) }}
            </div>
          </div>
        
          <input
            ref="volume_range"
            type="range"
            value="100"
            class="volume-range"
            style="display: none;"
          >

        </div>
        <div 
          class="player__right2"

        >
          <b-icon
            font-size="20"
            icon="arrow-up-right-square"
            color="#FFF"
            cursor="pointer"
            @click="playerExpandido()"
            class="d-md-none"
          />
          
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="playerExpandidoDesktop()"
            class="d-none d-lg-block"
          >
            Letra
          </b-button>

        </div>
      </div>
    </div>

    <!-- Modal de Negociação -->

    <b-modal
      id="modal-player-expandido"
      ref="modalPlayerExpandido"
      v-model="isModalPlayerExpandidoVisible"
      hide-footer
      hide-header
      centered
      modal-header-close
      no-close-on-backdrop
      size="lg"
    >
      <button type="button" class="close" aria-label="Fechar" @click="fecharPlayerExpandido">
            <b-icon
              size="18"
              cursor="pointer"
              icon="chevron-down"
              class="text-white"
            />
      </button>
      
      <div>

        <div class="row justify-content-center mt-3">
        <div class="col-md-6 text-center">
           
            <b-avatar
              size="80"
              variant="light-primary"
              class="badge-minimal"
            >
              <div class="boxContainer2">
                  <div class="box box1"></div>
                  <div class="box box2"></div>
                  <div class="box box3"></div>
                  <div class="box box4"></div>
                  <div class="box box5"></div>
              </div>
            </b-avatar>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6 text-center">
          <h5 class="current-track__name2 text-white">{{ hitMusicaAtual }}</h5>
        </div>
      </div>
      <div class="row justify-content-center mt-1">
        <div class="col-md-6 text-center">
          <!-- Player Controls -->
          <div class="player-controls">

            <b-icon
              size="40"
              class="player-controls__button icon-previous btIconPrevious text-white"
              cursor="pointer"
              icon="skip-backward"
              @click="prevMusic"
            />

            <div @click="playToggleBtn">
              <b-icon
                v-if="getPlayingStatus"
                size="40"
                icon="pause-circle-fill"
                cursor="pointer"
                class="player-controls__button player-controls__button--pause btIconPause text-white"
              />

              <b-icon
                v-else
                size="40"
                icon="play-circle-fill"
                cursor="pointer"
                class="player-controls__button player-controls__button--play btIconPlay text-white"
              />

            </div>

            <b-icon
              size="40"
              class="player-controls__button icon-next btIconNext text-white"
              cursor="pointer"
              icon="skip-forward"
              @click="nextMusic"
            />
            
            <feather-icon
              size="20"
              icon="StarIcon"
              :class="{favorito: hit.favorito}"
              style="cursor: pointer;margin-top: 13px;margin-left: 8px;"
              @click="favoritarHit(hit.id, hit.favorito)"
            />

          </div>
          <!-- Player Controls -->
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-md-6 text-center">

          <!-- Player Playback -->
          <div class="player-playback">
            <input
              ref="music_range"
              type="range"
              value="0"
              class="music-range"
              style="display: none;"
            >
            <div class="player-playback__time text-white">
              {{ getCurrentTime(currentTime) }}
            </div>
            <div class="player-playback__progress-bar">
              <vue-slider
                v-model="barra"
                :max="100"
                tooltip="none"
                :dot-size="12"
                :process-style="{ background: '#fe9856' }"
                :rail-style="{ background: 'rgb(115, 117, 117)' }"
                :style="{padding: '6.5px'}"
                @drag-start="onDragStart"
                @callback="onProgressChange"
                @drag-end="onDragEnd"
                @change="onProgressChange"
              />
            </div>
            <div class=" player-playback__time text-white">
              {{ getCurrentTime(durationTime) }}
            </div>
          </div>
          <!-- Player Playback -->

        </div>
      </div>

      <div class="section-letra overflow-auto row justify-content-center mt-2 mr-1 ml-1 p-2" style="max-height: 350px;">
        <p class="text-with-line-breaks textoLetra">{{ letraMusicaAtual }} {{ letraMusicaAtual }}</p>
      </div>

      <div class="row justify-content-center mt-2">
          <b-button
            variant="primary bg-gradient-primary"
            size="lg"
            block
            class="ml-2 mr-2"
            @click="modalNegociar(hit.id,hit.nomeComposicao,hit.valorExclusividade,hit.valorLiberacao)"
          >
            Negociar
          </b-button>
      </div>


      </div>
      

    </b-modal>

    <b-modal
      id="modal-player-expandido-desktop"
      ref="modalPlayerExpandidoDesktop"
      v-model="isModalPlayerExpandidoDesktopVisible"
      hide-footer
      hide-header
      centered
      modal-header-close
      no-close-on-backdrop
      size="md"
    >
      <button type="button" class="close" aria-label="Fechar" @click="fecharPlayerExpandidoDesktop">
            <b-icon
              size="18"
              cursor="pointer"
              icon="chevron-down"
              class="text-white"
            />
      </button>
      
      <div>

        <div class="row justify-content-center mt-3">
        <div class="col-md-6 text-center">
          
            <b-avatar
              size="80"
              variant="light-primary"
              class="badge-minimal"
            >
              <div class="boxContainer2">
                  <div class="box box1"></div>
                  <div class="box box2"></div>
                  <div class="box box3"></div>
                  <div class="box box4"></div>
                  <div class="box box5"></div>
              </div>
            </b-avatar>
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-md-6 text-center">
          <h5 class="current-track__name2 text-white">{{ hitMusicaAtual }}</h5>
        </div>
      </div>
      <div class="row justify-content-center mt-1">
        <div class="col-md-6 text-center">
          <!-- Player Controls -->
          <div class="player-controls">

            <b-icon
              size="40"
              class="player-controls__button icon-previous btIconPrevious text-white"
              cursor="pointer"
              icon="skip-backward"
              @click="prevMusic"
            />

            <div @click="playToggleBtn">
              <b-icon
                v-if="getPlayingStatus"
                size="40"
                icon="pause-circle-fill"
                cursor="pointer"
                class="player-controls__button player-controls__button--pause btIconPause text-white"
              />

              <b-icon
                v-else
                size="40"
                icon="play-circle-fill"
                cursor="pointer"
                class="player-controls__button player-controls__button--play btIconPlay text-white"
              />

            </div>

            <b-icon
              size="40"
              class="player-controls__button icon-next btIconNext text-white"
              cursor="pointer"
              icon="skip-forward"
              @click="nextMusic"
            />

            <feather-icon
              size="20"
              icon="StarIcon"
              :class="{favorito: hit.favorito}"
              style="cursor: pointer;margin-top: 13px;margin-left: 8px;"
              @click="favoritarHit(hit.id, hit.favorito)"
            />

          </div>
          <!-- Player Controls -->
        </div>
      </div>
      <div class="row justify-content-center mt-2">
        <div class="col-md-6 text-center">

          <!-- Player Playback -->
          <div class="player-playback">
            <input
              ref="music_range"
              type="range"
              value="0"
              class="music-range"
              style="display: none;"
            >
            <div class="player-playback__time text-white">
              {{ getCurrentTime(currentTime) }}
            </div>
            <div class="player-playback__progress-bar">
              <vue-slider
                v-model="barra"
                :max="100"
                tooltip="none"
                :dot-size="12"
                :process-style="{ background: '#fe9856' }"
                :rail-style="{ background: 'rgb(115, 117, 117)' }"
                :style="{padding: '6.5px'}"
                @drag-start="onDragStart"
                @callback="onProgressChange"
                @drag-end="onDragEnd"
                @change="onProgressChange"
              />
            </div>
            <div class=" player-playback__time text-white">
              {{ getCurrentTime(durationTime) }}
            </div>
          </div>
          <!-- Player Playback -->

        </div>
      </div>

      <div class="section-letra overflow-auto row justify-content-center mt-2 mr-1 ml-1 p-2" style="max-height: 300px;">
        <p class="text-with-line-breaks textoLetra">{{ letraMusicaAtual }} {{ letraMusicaAtual }}</p>
      </div>

      <div class="row justify-content-center mt-2 mb-2">
          <b-button
            variant="primary bg-gradient-primary"
            size="lg"
            block
            class="ml-2 mr-2"
            @click="modalNegociar(hit.id,hit.nomeComposicao,hit.valorExclusividade,hit.valorLiberacao)"
          >
            Negociar
          </b-button>
      </div>

      </div>
      

    </b-modal>

    <!-- Modal de Negociação -->

    <b-modal
      id="modal-negociar"
      ref="modalNegociar"
      ok-only
      ok-title="Enviar Proposta"
      centered
      no-close-on-backdrop
      size="sm"
      title="Negociar Hit"
      :hit-id="hitIdNegociarSelecionado"
      :hit-nome="hitNomeNegociarSelecionado"
      :hit-valor-exclusividade="hitValorExclusividadeNegociarSelecionado"
      :hit-valor-liberacao="hitValorLiberacaoNegociarSelecionado"
      @ok="negociarHit"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="negociarHit"
        >
          Enviar Proposta
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <h5>{{ hitNomeNegociarSelecionado }}</h5>

      <b-alert
        variant="primary"
        show
        class="mt-1"
      >
        <div class="alert-body">
          <span><strong>Envie a sua proposta!</strong> Essas são as sugestões do compositor.</span>
        </div>
      </b-alert>

      <b-row class="mt-2">

        <b-col>
          <!-- media -->
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                Liberação
              </h6>
              <small>{{ hitValorLiberacaoNegociarSelecionado | currency }}</small>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col>
          <!-- media -->
          <b-media
            no-body
          >
            <b-media-aside class="mr-1">
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                Exclusividade
              </h6>
              <small>{{ hitValorExclusividadeNegociarSelecionado | currency }}</small>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>

      <validation-observer
        #default="{invalid}"
        ref="negociacaoForm"
      >

        <!-- Form -->
        <b-form
          ref="negociacaoForms"
          class="mt-2"
          @submit.prevent="negociarHitCadastrar"
        >

          <b-row>

            <b-col>

              <!-- Tipo neogciação -->
              <b-form-group
                label="Tipo de Negociação"
                label-for="hitTipoNegociacao"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTipoNegociacao"
                >

                  <v-select
                    v-model="tipoNegociacaoSelecionada"
                    label="title"
                    :options="tipoNegociacao"
                  />

                </validation-provider>
              </b-form-group>
              <!-- Tipo neogciação -->

            </b-col>

          </b-row>

          <b-row>

            <b-col>

              <!-- Tempo  -->
              <b-form-group
                label="Tempo de Venda"
                label-for="hitTempoVenda"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitTempoVenda"
                >

                  <v-select
                    v-model="tempoVendaSelecionada"
                    label="title"
                    :options="tempoVenda"
                  />

                </validation-provider>
              </b-form-group>
              <!-- Tempo -->

            </b-col>

          </b-row>

          <b-row>

            <b-col>

              <!-- Estilos -->
              <b-form-group
                label="Valor"
                label-for="hitValorPropostaLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitValorPropostaLabel"
                >

                  <money
                    v-model="hitValorProposta"
                    v-bind="money"
                    class="form-control lg"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

  </div>
</template>

<script>

import Vue from 'vue'

import VueSlider from 'vue-slider-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BMedia, BImg, BAvatar, BButton, BAlert, BFormGroup,
} from 'bootstrap-vue'

import globalMixins from '@/mixins/globalMixins'

import useJwt from '@/auth/jwt/useJwt'

import VueCurrencyFilter from 'vue-currency-filter'

import { Money } from 'v-money'
import vSelect from 'vue-select'

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})

export default {
  name: 'ActiveMusic',
  components: {
    VueSlider,
    BRow,
    BCol,
    BMedia,
    BImg,
    BAvatar,
    BButton,
    BAlert,
    BFormGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    Money,
    VueCurrencyFilter,
  },
  mixins: [globalMixins],
  props: {
    logado: {
      type: String,
    }
  },
  data() {
    return {
      player: new Audio(),
      musics: [],
      activeMusic: null,
      musicStatusKod: 0,
      isPlay: false,
      isLoop: false,
      isRandom: false,
      currentTime: 0,
      durationTime: 0,
      barra: 0,
      isDragStart: false,
      hitMusicaAtual: '',
      hitMusicaAtualPlayer: '',
      letraMusicaAtual: '',
      ultimaChamada: -1,
      hit: [],
      isModalPlayerExpandidoVisible: false,
      isModalPlayerExpandidoDesktopVisible: false,

      // Hit Selecionado para Modal Negociar

      hitIdNegociarSelecionado: null,
      hitNomeNegociarSelecionado: null,
      hitValorExclusividadeNegociarSelecionado: null,
      hitValorLiberacaoNegociarSelecionado: null,

      tipoNegociacaoSelecionada: { id: 0, title: 'Liberação' },

      tipoNegociacao: [
        { id: 0, title: 'Liberação' },
        { id: 1, title: 'Exclusividade' },
      ],

      tempoVendaSelecionada: {id: 0, title: '12 meses' },
      tempoVenda: [
        { id: 0, title: '12 meses' },
        { id: 1, title: '18 meses' },
        { id: 2, title: '24 meses' },
      ],


      hitValorProposta: 0,
    }
  },
  computed: {
    getActivePlayList() {
      return this.$store.state.activePlayList
    },
    checkBookMarkMusic() {
      return this.getBookMarksIndex.includes(this.getActiveMusic.index)
    },
  },
  watch: {

    tipoNegociacaoSelecionada(newValue) {
      if (newValue.id === 0) {
        this.hitValorProposta = this.hitValorLiberacaoNegociarSelecionado
      } else if (newValue.id === 1) {
        this.hitValorProposta = this.hitValorExclusividadeNegociarSelecionado
      } else {
        this.hitValorProposta = this.hitValorLiberacaoNegociarSelecionado
      }

    },

    logado(novoValor, valorAntigo) {
      // atualizar o componente quando a propriedade logado mudar
      if(!novoValor) {
        //console.log('deslogado')
        //this.pause()
        this.player.src = ''
        this.hitMusicaAtual = ''
        this.activeMusic = null
        this.checkSameMusic(0)
        this.musicStatusKod = 0
      }

    },
    getActivePlayList() {
      this.updatePlayList()
    },
    getPlayingStatus(newStatus) {
      this.musicStatusKod = newStatus ? 2 : 3
    },
    getActiveMusic(value) {
      this.activeMusic = value
      this.changeMusic()
      this.musicStatusKod = 1
      this.hitMusicaAtual = this.getActiveMusic.nomeComposicao
      this.letraMusicaAtual = this.getActiveMusic.letra
      this.hit = this.getActiveMusic
    },
    musicStatusKod() {
      this.checkPlayStatus()
    },
    getBookMarksIndex: {
      handler() {
        this.updatePlayList()
      },
      deep: true,
    },
  },
  mounted() {
    this.setVolumeFromLocalStorage()
    this.updatePlayList()
    this.addEventListener()
    this.player.preload = 'auto'

  },
  methods: {

    // MODAL NEGOCIAR

    modalNegociar(hitId, hitNomeComposicao, hitValorExclusividade, hitValorLiberacao) {
      this.hitIdNegociarSelecionado = hitId
      this.hitNomeNegociarSelecionado = hitNomeComposicao
      this.hitValorExclusividadeNegociarSelecionado = hitValorExclusividade
      this.hitValorLiberacaoNegociarSelecionado = hitValorLiberacao
      this.tipoNegociacaoSelecionada = { id: 0, title: 'Liberação' }
      this.tempoVendaSelecionada = {id: 0, title: '12 meses' }
      this.hitValorProposta = hitValorLiberacao
      this.$refs.modalNegociar.show()
    },

    // FAVORITAR HIT

    favoritarHit(hitId, favorito) {
      let promise
      if (!favorito) {
        promise = useJwt.hitFavoritar(hitId)
      } else {
        promise = useJwt.hitDesfavoritar({ hitId })
      }

      promise
        .then(response => {
          const hit = this.getMusics.find(hit => hit.id === hitId)
          hit.favorito = !hit.favorito
        })
        .catch(error => {
          console.log(response)
        })
    },

    alterarValorLiberacao(val) {
      const valorLiberacao = val

      this.filtroLiberacaoMin = valorLiberacao[0]
      this.filtroLiberacaoMax = valorLiberacao[1]
    },

    alterarValorLiberacaoInput() {
      const valorLiberacao = []

      valorLiberacao.push(Number(this.filtroLiberacaoMin))
      valorLiberacao.push(Number(this.filtroLiberacaoMax))

      this.hitValorLiberacao = valorLiberacao
    },

    alterarValorExclusividade(val) {
      const valorExclusividade = val

      this.filtroExclusividadeMin = valorExclusividade[0]
      this.filtroExclusividadeMax = valorExclusividade[1]
    },

    alterarValorExclusividadeInput() {
      const valorExclusividade = []

      valorExclusividade.push(Number(this.filtroExclusividadeMin))
      valorExclusividade.push(Number(this.filtroExclusividadeMax))

      this.hitValorExclusividade = valorExclusividade
    },

    formatarMoeda(val) {
      return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },

    negociarHit() {
      this.$refs.negociacaoForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.negociacaoCadastrar({
            hitId: this.hitIdNegociarSelecionado,
            valor: this.hitValorProposta,
            tipoNegociacaoValor: this.tipoNegociacaoSelecionada.id,
            tempoVenda: this.tempoVendaSelecionada.id,
          })
            .then(response => {
              this.$swal({
                title: 'Proposta Enviada!',
                text: 'A sua proposta foi enviada ao compositor, o mesmo será notificado. Você pode acompanhar em "Negociações".',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              })
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
              this.$refs.modalNegociar.hide()
            })
        }
      })
    },

    playerExpandido() {
      this.isModalPlayerExpandidoVisible = true;
    },

    fecharPlayerExpandido() {
      this.isModalPlayerExpandidoVisible = false;
    },

    playerExpandidoDesktop() {
      this.isModalPlayerExpandidoDesktopVisible = true;
    },

    fecharPlayerExpandidoDesktop() {
      this.isModalPlayerExpandidoDesktopVisible = false;
    },

    onDragStart({ currentValue }) {
      this.isDragStart = true
    },

    onDragEnd() {
      if (this.isDragStart) {
        this.isDragStart = false;
        this.updateCurrentTime(this.barra);
      }
    },

    onProgressChange() {
      if (!this.isDragStart) {
        this.updateCurrentTime(this.barra);
      } else {
        // Atualizar o tempo atual durante o arraste
        const scrubTime = (this.barra * this.durationTime) / 100;
        this.currentTime = scrubTime;
      }
    },


    playToggleBtn() {
      if (this.musicStatusKod == 0) this.nextMusic()
      if (this.isPlay) {
        this.musicStatusKod = 5
      } else {
        this.musicStatusKod = 4
      }
    },
    nextMusic() {
      const newMusic = this.isRandom
        ? this.getNewMusic('random')
        : this.getNewMusic('next')
      this.checkSameMusic(newMusic)
    },
    prevMusic() {
      if (this.musicStatusKod == 0) return
      const newMusic = this.getNewMusic('prev')
      this.checkSameMusic(newMusic)
    },
    toggleLikeBtn() {
      const { index } = this.getActiveMusic
      if (index == -1) return
      if (!this.checkExistsBookMarksIndex(index)) {
        this.$store.commit('addBookMarkMusic', index)
      } else {
        this.$store.commit('removeBookMarkMusic', index)
      }
    },
    loopMusicToggleBtn() {
      this.isLoop = !this.isLoop
    },
    randomMusicToggleBtn() {
      this.isRandom = !this.isRandom
    },
    updatePlayList() {
      const playList = this.getActivePlayList
      if (playList == 'all') {
        this.musics = this.getMusics
      } else if (playList == 'bookmarks') {
        this.musics = this.getBookMarks().length > 0 ? this.getBookMarks() : this.getMusics
      }
    },
    checkSameMusic(newMusic) {
      if (newMusic.id !== this.getActiveMusic.id) { // ID DO HIT
        this.setActiveMusic(newMusic)
      } else {
        this.setActiveMusic(newMusic)
      }
    },
    checkPlayStatus() {
      switch (this.musicStatusKod) {
        case 1:
        case 2:
        case 4:
          this.play()
          this.updatePlayStatus(true)
          this.musicStatusKod = -1
          break
        case 3:
        case 5:
          this.pause()
          this.updatePlayStatus(false)
          this.musicStatusKod = -1
          break
        default:
          break
      }
    },
    contarReproducao(hitId){
      try {
        if(JSON.parse(localStorage.getItem('usuarioData')).authority === 'ROLE_COMPRADOR') {
          let promise = useJwt.contarReproducao({ hitId })
          promise
            .then(response => {
            })
            .catch(error => {
              console.log(error);
            })
        }
      } catch (error) {
        console.error('erro:', error);
      }
    },
    play() {
      const promise = this.player.play()
      if (promise !== undefined) {
        promise.catch(() => {
          this.player.play()
        })
      }
    },
    pause() {
      this.player.pause()
    },
    restartMusic() {
      this.player.currentTime = 0
    },
    changeMusic() {
      this.player.src = this.getActiveMusic.arquivo
      this.hitMusicaAtual = this.getActiveMusic.nomeComposicao
      this.letraMusicaAtual = this.getActiveMusic.letra
      this.hit = this.getActiveMusic

      if (this.isPlay || this.musicStatusKod === 4) {
        this.play();
      }

      this.$refs.music_range.style.pointerEvents = 'auto'
    },
    getNewMusic(status = 'next') {
      status = String(status).toLowerCase()
      const newIndex = this.getNewMusicIndex(status)
      return this.$store.state.musics[newIndex]
    },
    getNewMusicIndex(status) {
      const currentIndex = this.$store.state.musics.findIndex( 
        music => music.id == this.getActiveMusic.id,
      )
      const musicsCount = this.$store.state.musics.length
      let newIndex
      switch (status) {
        case 'next':
          newIndex = currentIndex == musicsCount - 1 ? 0 : currentIndex + 1
          break
        case 'prev':
          newIndex = currentIndex == 0 ? musicsCount - 1 : currentIndex - 1
          break
        case 'random':
          newIndex = Math.floor(Math.random() * musicsCount)
          break
      }
      return newIndex
    },
    addEventListener() {
      this.$refs.volume_range.addEventListener(
        'input',
        this.handleVolumeProgress,
      )
      this.$refs.music_range.addEventListener(
        'input',
        this.updateMusicProgress,
      )
      this.player.addEventListener('timeupdate', this.handleMusicProgress)
      this.player.addEventListener('canplay', () => {
        this.durationTime = this.player.duration
      })
    },
    handleMusicProgress() {

      if (this.isDragStart) {
        return; // Não fazer atualizações durante o arraste
      }

      if (this.isMusicOver()) {
        this.pause()
        this.restartMusic()
        if (this.isLoop) {
          this.play()
        } else {
          this.nextMusic()
        }
      }
      const percent = (this.player.currentTime / this.durationTime) * 100
      this.barra = percent 
      this.currentTime = this.player.currentTime
    },
    updateProgressBColor(input, percent) {
      input.style.background = `
        linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) ${percent}%,
         rgba(255, 255, 255, 0.2) ${percent}%, rgba(255, 255, 255, 0.2) 100%)`
    },
    updateMusicProgress(event) {
      this.updateCurrentTime(value)
    },
    handleVolumeProgress(event) {
      const { value } = event.target
      this.updateProgressBColor(event.target, value)
      this.player.volume = value / 100
      this.setLocalStorageData('volume', value)
    },
    updateCurrentTime(value) {
      const scrubTime = (value * this.durationTime) / 100
      this.player.currentTime = scrubTime
    },
    setVolumeFromLocalStorage() {
      const volume = parseFloat(this.getLocalStorageData('volume')) || 100
      const input = this.$refs.volume_range
      this.player.volume = volume / 100
      input.value = volume
      this.updateProgressBColor(input, volume)
    },
    getLocalStorageData(dataName) {
      return localStorage.getItem(dataName)
    },
    setLocalStorageData(dataName, data) {
      localStorage.setItem(dataName, data)
    },
    checkExistsBookMarksIndex(index) {
      const bookmarks = this.$store.state.bookMarksMusicsIndex
      return bookmarks.includes(index)
    },
    isMusicOver() {
      return Math.floor(this.currentTime) == Math.floor(this.durationTime)
    },

    getCurrentTime(time) {

      let hour = Math.floor(time / 3600)
      let minute = Math.floor(time / 60)
      let second = Math.floor(time - (hour * 3600 + minute * 60))

      try {
        if(second === 4) {
          if(this.ultimaChamada !== this.getActiveMusic.id){
            this.ultimaChamada = this.getActiveMusic.id;
            this.contarReproducao(this.getActiveMusic.id);
          }
        }
      } catch (error) {
        console.error('erro:', error);
      }

      hour = hour > 9 ? hour : `0${hour}`
      second = second > 9 ? second : `0${second}`
      minute = minute > 9 ? minute : `0${minute}`
      const formatTime = hour > 0 ? `${hour}:${minute}:${second}` : `${minute}:${second}`
      return formatTime
    },
  },
}
</script>

<style lang="scss">

  @import '@core/scss/vue/libs/vue-slider.scss';

  #modal-player-expandido .favorito, #modal-player-expandido-desktop .favorito {
    fill: rgb(255, 115, 0);
    color: transparent;
  }

  .player {
    //position: fixed;
    bottom: 0;
    // width: 100%;
    height: 70px;
    z-index: 2;
    background: linear-gradient(118deg, #2e2e2e, #000);
    // background: #242729;
    border-top: 1px solid #282828;
    padding: 0 5px;
    left: 260px;
    max-width: 100%;
  }

    .player__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    // .player__left {
    //   width: 20%;
    //   min-width: 230px;
    // }

    .player__right {
      width: 50%;
      // position: absolute;
      right: 20px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 120%;
      margin-right: 15px;
    }

    .player__right2 {
      right: 0;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      // height: 120%;
    }

    

    .device-picker {margin: 2px 5px 0 auto}

    .volume-bar {margin: 0 15px 0 5px}

      .player__center {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 15%;
        // flex-direction: column;
      }

      @media (max-width: 767px) {
        .player__center {
          width: 38%;
        }
      }

      .player-controls
      {display: flex;
      justify-content: center;
      // margin-bottom: 7px;
      }

      .player-controls__button
       { margin: auto 10px;
        color: #CCC;
        font-size: 15px;
        cursor: pointer;
        outline: none;
      }

      .player-controls__button:hover
          {color: #FFF}

      .player-controls__button--active
          {color: #008000}

      .player-controls__button--play,
      .player-controls__button--pause
      {font-size: 35px}

      .player-controls__button:hover
        {color: #FFF;
        transform: scale(1.1)}

        .player-playback {
  display: flex;
  width: 100%;
  justify-content: center; /* Centralize horizontalmente o conteúdo */
  align-items: center; /* Centralize verticalmente o conteúdo */
  margin-bottom: 5px; /* Adicione margem inferior para separar do elemento abaixo */
}

    .player-playback__time
      {min-width: 40px;font-size: 12px;color:#FFF;}

    .player-playback__progress-bar
      {width: 100%}

    .current-track
    {display: flex;
    align-items: center}

    .current-track__cover
    {
      width: 56px;
      min-width: 56px;
      height: 56px;
      margin: 0 15px;
    }

    .current-track__img
      {width: 100%}

    .current-track__info
    {
      position: relative;
      overflow: hidden;
      padding-right: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .track-addition
    {
      position: absolute;
      right: 0;
      top: 2px;
    }

    .musicaAtual {
      //height: 21px;
      //position: fixed;
      margin-top: 2px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .current-track__name
    {
      color: #FFF;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: -1px;
    }

    .current-track__name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .current-track__name {
      text-transform: lowercase;
    }

    .current-track__name::first-line {
        text-transform: capitalize;
    }

    .current-track__name2 {
      font-size: 16px;
      text-transform: lowercase;
    }

    .current-track__name2::first-line {
        text-transform: capitalize;
    }

    .current-track__artists
    {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 11px;
      color: #CCC;
    }

    .current-track__link
    {color: #CCC}


    // EQUALIZER

    @keyframes quiet {
      25%{
        transform: scaleY(.6);
      }
      50%{
        transform: scaleY(.4);
      }
      75%{
        transform: scaleY(.8);
      }
    }

    @keyframes normal {
      25%{
        transform: scaleY(1);
      }
      50%{
        transform: scaleY(.4);
      }
      75%{
        transform: scaleY(.6);
      }
    }
    @keyframes loud {
      25%{
        transform: scaleY(1);
      }
      50%{
        transform: scaleY(.4);
      }
      75%{
        transform: scaleY(1.2);
      }
    }


    .boxContainer{
      display: flex;
      justify-content: space-between;
      margin-left: 6px;
      margin-right: 7px;
      height: 20px;
      --boxSize: 2px;
      --gutter: 1px;
      width: calc((var(--boxSize) + var(--gutter)) * 5);
    }

    .box{
      transform: scaleY(.4);
      height: 100%;
      width: var(--boxSize);
      background: #ff7300;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      border-radius: 8px;
    }

    .box1{
      animation-name: quiet;
    }

    .box2{
      animation-name: normal;
    }

    .box3{
      animation-name: quiet;
    }

    .box4{
      animation-name: loud;
    }

    .box5{
      animation-name: quiet;
    }

    .player__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column; /* Adicione essa propriedade para alterar o layout para uma coluna */
    }

    // Modal Player Expandido

    #modal-player-expandido .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #modal-player-expandido .modal-content {
      background: linear-gradient(90deg, #2e2e2e, #000);
      color: #FFF;
    }

    #modal-player-expandido .modal-content {
      width: 100% !important;
      height: 100% !important;
      border: none;
    }

    #modal-player-expandido .section-letra {
      background-color: #2e2e2e;
      border: 1px solid #7f7f7f;
      color: #FFF;
      // border-bottom-left-radius: 15px;
      // border-bottom-right-radius: 15px;
      border-radius: 12px;
      font-size: 18px;
    }

    .textoLetra {
      line-height: 28px;
    }

    /* Works on Firefox */
    #modal-player-expandido .section-letra {
      scrollbar-width: thin;
      scrollbar-color: #ff7300 #000;
    }

    /* Works on Chrome, Edge, and Safari */
    #modal-player-expandido .section-letra::-webkit-scrollbar {
      width: 12px;
    }

    #modal-player-expandido .section-letra::-webkit-scrollbar-track {
      background: #ff7300;
    }

    #modal-player-expandido .section-letra::-webkit-scrollbar-thumb {
      background-color: #ff7300;
      border-radius: 20px;
      border: 3px solid #ff7300;
    }

    #modal-player-expandido {
      padding: 0 !important;
    }

    #modal-player-expandido {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .boxContainer2 {
      display: flex;
      justify-content: space-between;
      margin-left: 6px;
      margin-right: 7px;
      height: 20px;
      --boxSize: 2px;
      --gutter: 1px;
      width: calc((var(--boxSize) + var(--gutter)) * 5);
    }

    .btIconPlay, .btIconPause {
      font-size: 45px !important;
    }

    .btIconPrevious, .btIconNext {
      font-size: 30px !important;
    }

    .text-with-line-breaks {
      white-space: pre-line;
    }

    // Modal Player Expandido

    // Modal Player Expandido Desktop

    #modal-player-expandido-desktop .modal-content {
      background: linear-gradient(90deg, #2e2e2e, #000);
      color: #FFF;
    }

    #modal-player-expandido-desktop .section-letra {
      background-color: #2e2e2e;
      border: 1px solid #7f7f7f;
      color: #FFF;
      // border-bottom-left-radius: 15px;
      // border-bottom-right-radius: 15px;
      border-radius: 12px;
      font-size: 16px;
    }

    /* Works on Firefox */
    #modal-player-expandido-desktop .section-letra {
      scrollbar-width: thin;
      scrollbar-color: #ff7300 #000;
    }

    /* Works on Chrome, Edge, and Safari */
    #modal-player-expandido-desktop .section-letra::-webkit-scrollbar {
      width: 12px;
    }

    #modal-player-expandido-desktop .section-letra::-webkit-scrollbar-track {
      background: #ff7300;
    }

    #modal-player-expandido-desktop .section-letra::-webkit-scrollbar-thumb {
      background-color: #ff7300;
      border-radius: 20px;
      border: 3px solid #ff7300;
    }

    #modal-player-expandido-desktop {
      padding: 0 !important;
    }

    #modal-player-expandido-desktop .textoLetra {
      line-height: 23px;
    }


    // Modal Player Expandido Desktop

</style>
