export default [

  {
    path: '/mercado',
    name: 'hit-comprador',
    component: () => import('@/views/pages/hits/HitCompradorLista.vue'),
    meta: {
      pageTitle: 'Mercado',
      resource: 'MercadoComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Mercado',
          active: true,
        },
      ],
    },
  },

  {
    path: '/hits',
    name: 'meus-hits-comprador',
    component: () => import('@/views/pages/hits/MeusHitsCompradorLista.vue'),
    meta: {
      pageTitle: 'Meus Hits',
      resource: 'MercadoComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Mercado',
          active: true,
        },
      ],
    },
  },

  {
    path: '/hits/info/:id',
    name: 'meus-hits-comprador-info',
    component: () => import('@/views/pages/hits/MeusHitsCompradorInfo.vue'),
    meta: {
      pageTitle: 'Meus Hits',
      resource: 'MercadoComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Mercado',
          active: true,
        },
      ],
    },
  },

]
