export default [

  {
    path: '/favoritos',
    name: 'hits-favoritos',
    component: () => import('@/views/pages/hits/HitCompradorFavorito.vue'),
    meta: {
      pageTitle: 'Favoritos',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Favoritos',
          active: true,
        },
      ],
    },
  },

]
