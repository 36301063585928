import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import inicio from './routes/inicio'
import hits from './routes/hits'
import hitsComprador from './routes/hits-comprador'
import hitsGestor from './routes/hits-gestor'
import campanhas from './routes/campanhas'
import favoritos from './routes/favoritos'
import playlist from './routes/playlist'
import negociacoes from './routes/negociacoes'
import aprovacoes from './routes/aprovacoes'
import usuarios from './routes/usuarios'
import configuracoes from './routes/configuracoes'
import financeiro from './routes/financeiro'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import contaConfiguracoes from './routes/conta-configuracoes'
import gestao from './routes/gestao'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
    },
    ...inicio,
    ...hits,
    ...hitsComprador,
    ...hitsGestor,
    ...campanhas,
    ...favoritos,
    ...playlist,
    ...negociacoes,
    ...aprovacoes,
    ...usuarios,
    ...configuracoes,
    ...gestao,
    ...financeiro,
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...contaConfiguracoes,
    {
      path: '*',
      redirect: { name: 'erro-404' },
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     localStorage.setItem('originalUrl', to.path)
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'autenticacao-login' })

//     // If logged in => not authorized
//     return next({ name: 'nao-autorizado' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const usuarioData = getUserData()
//     next(getHomeRouteForLoggedInUser(usuarioData ? usuarioData.authority : null))
//   }

//   return next()
// })

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // console.log('Tem acesso: ' + canNavigate(to))

  // console.log(to.path)

  if (to.path === '/' && isLoggedIn) {
    const userData = getUserData()
    if (userData) {
      const homeRoute = getHomeRouteForLoggedInUser(userData.authority)
      return next(homeRoute)
    }
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      localStorage.setItem('originalUrl', to.path)
      return next({ name: 'autenticacao-login' })
    } else if (to.name !== 'nao-autorizado') {
      return next({ name: 'nao-autorizado' })
    }
  }

  return next()
})

// router.beforeEach(async (to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   const userData = getUserData()


//   // Redirect if logged in
//   if (to.path === '/' && isLoggedIn) {
//     const userData = getUserData()
//     if (userData) {
//       const homeRoute = getHomeRouteForLoggedInUser(userData.authority)
//       return next(homeRoute)
//     }
//   }
  

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) {
//       localStorage.setItem('originalUrl', to.path)
//       return next({ name: 'autenticacao-login' })
//     } else if (to.name !== 'nao-autorizado') {
//       return next(false)
//     }
//   }
  

//   return next()
// })



// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
