export default [

  {
    path: '/playlists',
    name: 'hits-playlist',
    component: () => import('@/views/pages/usuarios/comprador/comprador-editar/HitCompradorPlaylist.vue'),
    meta: {
      pageTitle: 'Playlist',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Playlist',
          active: true,
        },
      ],
    },
  },

  {
    path: '/playlists/:id',
    name: 'hits-playlist-info',
    component: () => import('@/views/pages/usuarios/comprador/comprador-editar/HitCompradorPlaylistInfo.vue'),
    props: true,
    meta: {
      pageTitle: 'Playlist',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Playlist',
          active: true,
        },
      ],
    },
  }
  

]
