export default [

  {
    path: '/configuracao/temas',
    name: 'temas-lista',
    component: () => import('@/views/pages/configuracoes/hit/temas/TemasLista.vue'),
    meta: {
      pageTitle: 'Temas',
      resource: 'TemasLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Temas',
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/configuracao/temas/cadastrar',
    name: 'temas-cadastrar',
    component: () => import('@/views/pages/configuracoes/hit/temas/TemasLista.vue'),
    meta: {
      pageTitle: 'Temas',
      resource: 'TemasLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Temas',
        },
        {
          text: 'Cadastrar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/configuracao/estilos',
    name: 'estilos-lista',
    component: () => import('@/views/pages/configuracoes/hit/estilos/EstilosLista.vue'),
    meta: {
      pageTitle: 'Estilos',
      resource: 'EstilosLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Estilos',
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/configuracao/perfis',
    name: 'perfil-lista',
    component: () => import('@/views/pages/configuracoes/perfil/PerfilLista.vue'),
    meta: {
      pageTitle: 'Perfil',
      resource: 'PerfilLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Perfis',
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configuracao/perfis/:id',
    name: 'perfil-editar',
    component: () => import('@/views/pages/configuracoes/perfil/PerfilConfiguracao.vue'),
    meta: {
      pageTitle: 'Perfil',
      resource: 'PerfilLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Perfis',
          to: '/configuracao/perfis'
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configuracao/ability',
    name: 'ability-lista',
    component: () => import('@/views/pages/configuracoes/ability/AbilityLista.vue'),
    meta: {
      pageTitle: 'Funcionalidades',
      resource: 'FuncionalidadeLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Funcionalidades',
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configuracao/parametros',
    name: 'parametro-lista',
    component: () => import('@/views/pages/configuracoes/parametros/ParametroLista.vue'),
    meta: {
      pageTitle: 'Parametros',
      resource: 'ParametroLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Parametros',
        },
        {
          text: 'Gerenciar',
          active: true,
        },
      ],
    },
  },
]
