<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout" class="pb-5">
      <router-view/>
    </component>

    <!-- <scroll-to-top v-if="enableScrollToTop" /> -->

    <HitPlayer v-show="logado" :logado="logado" class="footer fixed-bottom bg-dark" style="z-index:1039;"/>
    <!-- <HitPlayer v-show="logado" class="footer fixed-bottom bg-dark" style="z-index:1039;"/> -->

    <cookie-consent class="text-center">
      <template
        slot="message"
      >
        <div class="mb-2">
        Os cookies nos permitem melhorar sua experiência no Mercado Hit.
        </div>
        
      </template>
      <template
        slot="button"
      >
        <div class="mb-2">
        <a class="btn btn-link" href="#">Saiba mais</a>
        <button class="btn btn-gradient-primary">Aceitar cookies</button>
        </div>
      </template>
    </cookie-consent>

  </div>

</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import HitPlayer from '@/views/pages/hits/HitPlayer.vue'
import globalMixins from '@/mixins/globalMixins'

import { isUserLoggedIn } from '@/auth/utils'

import CookieConsent from 'vue-cookieconsent-component'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    HitPlayer,
    CookieConsent,
  },
  data() {

    return {
      logado: null,
    }

  },
  mixins: [globalMixins],
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    this.logado = isUserLoggedIn()
    // console.log(this.$route)
  },
  watch: {
    '$route': function (to, from) {
      this.logado = isUserLoggedIn()
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>

<style lang="scss">

// required and to get updates
@import "~vue-cookieconsent-component/src/scss/cookie-consent";

// example or use it
@import "~vue-cookieconsent-component/src/scss/cookie-consent-bottom";
@import "~vue-cookieconsent-component/src/scss/cookie-consent-transition";

.cookie-consent {
    background: #FFF;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
    -webkit-box-shadow: 0 -8px 16px rgba(0,0,0,.1);
    box-shadow: 0 -8px 16px rgba(0,0,0,.1);
}

footer {
  height: 120px; /* ajuste a altura aqui */
}

</style>
