export default [

  // COMPOSITOR

  {
    path: '/negociacoes',
    name: 'negociacoes',
    component: () => import('@/views/pages/negociacoes/compositor/NegociacaoLista.vue'),
    meta: {
      pageTitle: 'Negociações',
      resource: 'Negociacoes',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negociações',
        },
      ],
    },
  },

  {
    path: '/negociacoes/vendidas',
    name: 'negociacoes-vendidas',
    component: () => import('@/views/pages/negociacoes/compositor/NegociacaoLista.vue'),
    meta: {
      pageTitle: 'Negociações',
      resource: 'Negociacoes',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negociações',
        },
      ],
    },
  },

  {
    path: '/negociacao/:id',
    name: 'negociacao-visualizar',
    component: () => import('@/views/pages/negociacoes/compositor/Negociacao.vue'),
    meta: {
      pageTitle: 'Histórico de Propostas',
      resource: 'Negociacoes',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negociações',
          to: { name: 'negociacoes' },
        },
        {
          text: 'Proposta',
          active: true,
        },
      ],
    },
  },

]
