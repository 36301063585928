export default [

  { 
    path: '/usuarios/compositor',
    name: 'usuarios-compositor',
    component: () => import('@/views/pages/usuarios/compositor/CompositorLista.vue'),
    meta: {
      resource: 'UsuarioCompositorLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Compositor',
        },
      ],
    },
  },
  {
    path: '/usuarios/compositor/editar/:id',
    name: 'usuarios-compositor-editar',
    component: () => import('@/views/pages/usuarios/compositor/compositor-editar/CompositorEditar.vue'),
    meta: {
      pageTitle: 'Compositor',
      resource: 'UsuarioCompositorInfoEdit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/usuarios/compositor',
        },
        {
          text: 'Compositor',
          to: '/usuarios/compositor',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  { 
    path: '/usuarios/compositor/editar/hit/:id',
    name: 'usuarios-compositor-editar-hit',
    component: () => import('@/views/pages/usuarios/compositor/compositor-editar/CompositorHitsEditar.vue'),
    meta: {
      resource: 'UsuarioCompositorInfoEdit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/usuarios/compositor',
        },
        {
          text: 'Compositor',
          to: '/usuarios/compositor',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

  { 
    path: '/usuarios/comprador',
    name: 'usuarios-comprador',
    component: () => import('@/views/pages/usuarios/comprador/CompradorLista.vue'),
    meta: {
      resource: 'UsuarioCompradorLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Comprador',
        },
      ],
    },
  },
  {
    path: '/usuarios/comprador/editar/:id',
    name: 'usuarios-comprador-editar',
    component: () => import('@/views/pages/usuarios/comprador/comprador-editar/CompradorEditar.vue'),
    meta: {
      pageTitle: 'Comprador',
      resource: 'UsuarioCompradorInfoEdit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/usuarios/comprador'
        },
        {
          text: 'Comprador',
          to: '/usuarios/comprador'
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/usuarios/comprador/editar/audicao/:id',
    name: 'usuarios-comprador-audicao-editar',
    component: () => import('@/views/pages/usuarios/comprador/comprador-editar/CompradorAudicoesEditar.vue'),
    meta: {
      pageTitle: 'Edição de Audição',
      resource: 'UsuarioCompradorInfoEdit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/usuarios/comprador'
        },
        {
          text: 'Comprador',
          to: '/usuarios/comprador'
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]
