export default [

  {
    path: '/campanhas',
    name: 'campanhas',
    component: () => import('@/views/pages/campanhas/CampanhaLista.vue'),
    meta: {
      pageTitle: 'Audições',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
        },
      ],
    },
  },

  {
    path: '/campanha/cadastrar',
    name: 'campanha-cadastrar',
    component: () => import('@/views/pages/campanhas/CampanhaCadastrar.vue'),
    meta: {
      pageTitle: 'Audição',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
        },
        {
          text: 'Cadastrar Audição',
          active: true,
        },
      ],
    },
  },

  {
    path: '/campanha/:id',
    name: 'campanha-comprador-visualizar',
    component: () => import('@/views/pages/campanhas/Campanha.vue'),
    meta: {
      pageTitle: 'Audição',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
          to: { name: 'campanhas' },
        },
        {
          text: 'Visualizar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/campanhas/pendencias',
    name: 'campanha-pendencias',
    component: () => import('@/views/pages/campanhas/pendencias/CampanhaPendencias.vue'),
    meta: {
      pageTitle: 'Pendências',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
        },
        {
          text: 'Pendências',
          active: true,
        },
      ],
    },
  },

  {
    path: '/campanha/editar/:id',
    name: 'campanha-editar',
    component: () => import('@/views/pages/campanhas/editar/CampanhaEditar.vue'),
    meta: {
      pageTitle: 'Editar Audição',
      resource: 'CampanhasComprador',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audição',
        },
        {
          text: 'Editar Audição',
          active: true,
        },
      ],
    },
  },

  // COMPOSITOR

  {
    path: '/campanhas/ativas',
    name: 'campanhas-compositor',
    component: () => import('@/views/pages/campanhas/compositor/CampanhaLista.vue'),
    meta: {
      pageTitle: 'Audições',
      resource: 'CampanhaCompositor',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
        },
      ],
    },
  },

  {
    path: '/campanha/participar/:id',
    name: 'campanha-compositor-visualizar',
    component: () => import('@/views/pages/campanhas/compositor/Campanha.vue'),
    meta: {
      pageTitle: 'Audição',
      resource: 'CampanhaCompositor',
      action: 'read',
      breadcrumb: [
        {
          text: 'Audições',
          to: { name: 'campanhas-compositor' },
        },
        {
          text: 'Participar',
          active: true,
        },
      ],
    },
  },

]
