export default [

  {
    path: '/dashboard/compositor',
    name: 'inicio-compositor',
    component: () => import('@/views/dashboard/compositor/Compositor.vue'),
    meta: {
      resource: 'DashboardCompositor',
      action: 'read',

    },
  },

  {
    path: '/dashboard/comprador',
    name: 'inicio-comprador',
    component: () => import('@/views/dashboard/comprador/Comprador.vue'),
    meta: {
      resource: 'DashboardComprador',
      action: 'read',

    },
  },

  {
    path: '/dashboard/gestor',
    name: 'inicio-gestor',
    component: () => import('@/views/dashboard/gestao/Gestao.vue'),
    meta: {
      resource: 'DashboardGestao',
      action: 'read',

    },
  },

]
