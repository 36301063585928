export default {
  computed: {
    getMusics() {
      //console.log(this.$store.state.musics);
      return this.$store.state.musics
      // return this.$store.state.playList;
    },
    getActiveMusic() {
      //console.log(this.$store.state.activeMusic)
      return this.$store.state.activeMusic
    },
    getPlayingStatus() {
      return this.$store.state.isPlay
    },
    getBookMarksIndex() {
      return this.$store.state.bookMarksMusicsIndex
    },
  },
  methods: {
    getActiveMusicIndex() {
      // console.log( this.getActiveMusic.id  );
      return this.getActiveMusic.id // ID DO HIT
    },
    setActiveMusic(music) {
      //console.log( music );
      this.$store.commit('setCurrentMusic', music) // SELECIONA A LINHA TODA DO HIT
    },
    updatePlayStatus(status) {
      //console.log( status )
      const conditions = Object.is(this.isPlay, undefined) || Object.is(this.isPlay, null)
      if (!conditions) this.isPlay = status
      this.$store.commit('updatePlayStatus', status)
    },
    getBookMarks() {
      const musics = this.getMusics
      const bookMarksIndex = this.getBookMarksIndex
      const bookMarks = []
      bookMarksIndex.forEach(index => {
        const music = musics.find(music => music.index == index)
        bookMarks.push(music)
      })
      return bookMarks
    },
  },
}
