import qs from 'qs'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {

          // if (!this.isAlreadyFetchingAccessToken) {
          //   this.isAlreadyFetchingAccessToken = true
          //   this.refreshToken().then(r => {
          //     this.isAlreadyFetchingAccessToken = false

          //     // Update accessToken in localStorage
          //     this.setToken(r.data.accessToken)
          //     this.setRefreshToken(r.data.refreshToken)

          //     this.onAccessTokenFetched(r.data.accessToken)
          //   })
          // }
          // const retryOriginalRequest = new Promise(resolve => {
          //   this.addSubscriber(accessToken => {
          //     // Make sure to assign accessToken according to your response.
          //     // Check: https://pixinvent.ticksy.com/ticket/2413870
          //     // Change Authorization header
          //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          //     resolve(this.axiosIns(originalRequest))
          //   })
          // })
          // return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  usuarioCompositorCadastro(...args) {
    return this.axiosIns.post(this.jwtConfig.usuarioCompositorCadastroEndpoint, ...args)
  }
  
  usuarioConfirmarCadastro(...args) {
    const { token } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.usuarioConfirmarCadastroEndpoint}/${token}`, ...args)
  }

  usuarioEsqueciSenha(...args) {
    // const { token } = args[0]
    return this.axiosIns.post(this.jwtConfig.usuarioEsqueciSenhaEndpoint, ...args)
  }

  usuarioNovaSenha(...args) {
    return this.axiosIns.post(this.jwtConfig.usuarioNovaSenhaEndpoint, ...args)
  }

  usuarioTrocarSenha(...args) {
    return this.axiosIns.post(this.jwtConfig.usuarioTrocarSenhaEndpoint, ...args)
  }

  usuarioDados(...args) {
    return this.axiosIns.get(this.jwtConfig.usuarioCompositorDadosEndpoint, ...args)
  }

  usuarioDadosComprador(...args) {
    return this.axiosIns.get(this.jwtConfig.usuarioCompradorDadosEndpoint, ...args)
  }

  usuarioDadosGestor(...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.get(`${this.jwtConfig.usuarioGestorDadosEndpoint}/${usuarioId}`)
  }

  usuarioEnderecoDados(...args) {
    return this.axiosIns.get(this.jwtConfig.usuarioEnderecoDadosEndpoint, ...args)
  }

  usuarioEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioEditarCompositorEndpoint, ...args)
  }

  usuarioCompradorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioEditarCompradorEndpoint, ...args)
  }

  usuarioGestorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioEditarGestorEndpoint, ...args)
  }

  usuarioEnderecoEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioEnderecoEditarEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  // Totalizador - Compositor

  dashboardCompositorCarregarSaldo() {
    return this.axiosIns.get(this.jwtConfig.dashboardCompositorCarregarSaldoEndpoint)
  }

  dashboardCompositorTotalizador() {
    return this.axiosIns.get(this.jwtConfig.dashboardCompositorTotalizadorEndpoint)
  }

  // Totalizador - Comprador

  dashboardCompradorTotalizador() {
    return this.axiosIns.get(this.jwtConfig.dashboardCompradorTotalizadorEndpoint)
  }

  // Hits - Favoritos - Comprador

  hitCompradorFavorito(...args) {
    return this.axiosIns.get(this.jwtConfig.hitCompradorFavoritoEndpoint, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  hitCompradorFavoritoLista(...args) {
      const usuarioId = args[0].usuarioId
      return this.axiosIns.get(`${this.jwtConfig.hitCompradorFavoritoListaEndpoint}/${usuarioId}`, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  // Hit

  hitTemasLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitTemasListaEndpoint, { params: args[0] })
  }

  hitEstilosLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitEstilosListaEndpoint, { params: args[0] })
  }

  hit(...args) {
    const hitId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.hitEndpoint}/${hitId}`, ...args)
  }

  hitCompleto(...args) {
    const hitId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.hitCompletoEndpoint}/${hitId}`, ...args)
  }

  hitLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitListaEndpoint, ...args)
  }

  hitListaGestao(...args) {
    const campanhaId = args[0].id
    let alterarEndpoint = this.jwtConfig.hitEndpoint

    if (campanhaId) {

      alterarEndpoint = `${this.jwtConfig.campanhaCompositorHitsIdEndpoint}/${campanhaId}`

    }
    //IDENTIFICAR SE É VINDO DE CAMPANHA

    return this.axiosIns.get(alterarEndpoint, {

      params: {
        campanhaId: args[0].id,
        liberacaoMin: args[0].liberacaoMin,
        liberacaoMax: args[0].liberacaoMax,
        exclusividadeMin: args[0].exclusividadeMin,
        exclusividadeMax: args[0].exclusividadeMax,
        nomeComposicao: args[0].nomeComposicao,
        listaTemas: args[0].listaTemas,
        listaEstilos: args[0].listaEstilos,
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  hitCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.hitCadastrarEndpoint, ...args)
  }

  hitCompositorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.hitCompositorEditarEndpoint, ...args)
  }

  hitGestorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.hitGestorEditarEndpoint, ...args)
  }

  hitUpload(...args) {
    const { hitId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.hitUploadEndpoint}/${hitId}`, ...args)
  }

  hitCompositorEditarUpload(...args) {
    const { hitId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.hitCompositorEditarUploadEndpoint}/${hitId}`, ...args)
  }

  // Hit - Compositor

  hitCompositorLista(...args) {

    return this.axiosIns.get(this.jwtConfig.hitCompositorListaEndpoint, {

      params: {
        statusAprovacao: args[0].statusAprovacao,
        nomeComposicao: args[0].nomeComposicao,
        liberacaoMin: args[0].liberacaoMin,
        liberacaoMax: args[0].liberacaoMax,
        exclusividadeMin: args[0].exclusividadeMin,
        exclusividadeMax: args[0].exclusividadeMax,
        listaTemas: args[0].listaTemas,
        listaEstilos: args[0].listaEstilos,
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  hitCompositorId(...args) {
    const hitId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.hitCompositorIdEndpoint}/${hitId}`)
  }

  hitCompositorPendencias(...args) {

    return this.axiosIns.get(this.jwtConfig.hitCompositorPendenciasEndpoint, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  hitCampanhaEnviar(...args) {
    return this.axiosIns.post(this.jwtConfig.hitCampanhaEnviarEndpoint, ...args)
  }

  hitCompositorCampanhaParticipacao(...args) {
    const campanhaId = args[0].campanhaId
    return this.axiosIns.get(`${this.jwtConfig.hitCompositorCampanhaParticipacaoEndpoint}/${campanhaId}`)
  }

  contarReproducao(...args) {
    const { hitId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.contarReproducaoEndpoint}/${hitId}`)
  }

  contarQtdReproducao(...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.get(`${this.jwtConfig.contarQtdReproducaoEndpoint}/${usuarioId}`, {
      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    }) 
  }

  // não sendo usado
  contarQtdReproducaoTotal  (...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.get(`${this.jwtConfig.contarQtdReproducaoTotalEndpoint}/${usuarioId}`) 
  }

  downloadHit(...args) {
    const { hitId } = args[0]
    return this.axiosIns.get(`${this.jwtConfig.hitDownloadEndpoint}/${hitId}`)
  }

  // Hit - Comprador

  hitCompradorLista(...args) {
    //IDENTIFICAR SE É VINDO DE CAMPANHA
    const campanhaId = args[0].id
    let alterarEndpoint = this.jwtConfig.hitCompradorListaEndpoint

    if (campanhaId) {

      alterarEndpoint = `${this.jwtConfig.campanhaCompositorHitsIdEndpoint}/${campanhaId}`

    }
    //IDENTIFICAR SE É VINDO DE CAMPANHA

    return this.axiosIns.get(alterarEndpoint, {

      params: {
        campanhaId: args[0].id,
        liberacaoMin: args[0].liberacaoMin,
        liberacaoMax: args[0].liberacaoMax,
        exclusividadeMin: args[0].exclusividadeMin,
        exclusividadeMax: args[0].exclusividadeMax,
        listaTemas: args[0].listaTemas,
        listaEstilos: args[0].listaEstilos,
        reproduzido: args[0].reproduzido,
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })

  }

  hitPertenceCompradorLista(...args) {
    const usuarioId = args[0].usuarioId
      return this.axiosIns.get(`${this.jwtConfig.hitPerteceCompradorListaEndpoint}/${usuarioId}`, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })

  }

  hitPertenceCompradorInfo(...args) {
    const hitId = args[0].hitId
    return this.axiosIns.get(`${this.jwtConfig.hitPertenceCompradorInfoEndpoint}/${hitId}`)
  }

  hitHistoricoCompradorInfo(...args) {
      const hitId = args[0].hitId
      return this.axiosIns.get(`${this.jwtConfig.hitHistoricoCompradorInfoEndpoint}/${hitId}`)
  }

  hitFavoritar(...args) {
    const hitId = args[0]
    return this.axiosIns.post(this.jwtConfig.hitFavoritarEndpoint, hitId, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  hitDesfavoritar(...args) {
    const { hitId } = args[0]
    return this.axiosIns.delete(`${this.jwtConfig.hitFavoritarEndpoint}/${hitId}`)
  }

  // Playlist - Comprador
  playlistListar(...args) {
    const usuarioId = args[0].usuarioId
      return this.axiosIns.get(`${this.jwtConfig.playlistListarEndpoint}/${usuarioId}`, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  playlistHits(...args) {
    const playlistId = args[0].playlistId
      return this.axiosIns.get(`${this.jwtConfig.playlistHitsEndpoint}/${playlistId}`, {

        params: {
          playlistId: args[0].playlistId,
          liberacaoMin: args[0].liberacaoMin,
          liberacaoMax: args[0].liberacaoMax,
          exclusividadeMin: args[0].exclusividadeMin,
          exclusividadeMax: args[0].exclusividadeMax,
          nomeComposicao: args[0].nomeComposicao,
          listaTemas: args[0].listaTemas,
          listaEstilos: args[0].listaEstilos,
          reproduzido: args[0].reproduzido,
          page: args[0].page,
          size: args[0].size,
          sort: args[0].sort,
        },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  playlistCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.playlistCriarEndpoint, ...args)
  }
  
  playlistRemover(...args) {
    const playlistId = args[0].playlistId
    return this.axiosIns.delete(`${this.jwtConfig.playlistRemoverHitEndpoint}/${playlistId}`);
  }

  playlistAdicionarMusica(...args) {
    return this.axiosIns.post(this.jwtConfig.playlistAddHitEndpoint, ...args)
  }

  playlistRemoverMusica(...args) {
    const playlistId = args[0].playlistId
    const hitId = args[0].hitId
    return this.axiosIns.delete(`${this.jwtConfig.playlistRemoverHitEndpoint}/${playlistId}/${hitId}`);
  }

  // Aprovações - Hit

  // Hits
  hitAprovacoesLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitAprovacoesListaEndpoint, { params: args[0] })
  }

  hitAprovar(...args) {
    return this.axiosIns.patch(this.jwtConfig.hitAprovarEndpoint, ...args)
  }

  hitLetraEditar(...args) {
    const hitId = args[0].hitId
    return this.axiosIns.put(`${this.jwtConfig.hitLetraEditarEndpoint}/${hitId}`, ...args)
  }

  hitReprovar(...args) {
    return this.axiosIns.patch(this.jwtConfig.hitReprovarEndpoint, ...args)
  }


  // Campanhas

  campanhaAprovacoesLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhaAprovacoesListaEndpoint, { params: args[0] })
  }

  campanhaAprovar(...args) {
    return this.axiosIns.patch(this.jwtConfig.campanhaAprovarEndpoint, ...args)
  }

  campanhaReprovar(...args) {
    return this.axiosIns.patch(this.jwtConfig.campanhaReprovarEndpoint, ...args)
  }

  // Usuários
  usuarioTrocarSenha(...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.put(`${this.jwtConfig.usuarioTrocarSenhaEndpoint}/alterar-senha/${usuarioId}`, {
        senhaAntiga: args[0].senhaAntiga,
        novaSenha: args[0].novaSenha,
    })
  }

  usuarioReenviarSenha(...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.put(`${this.jwtConfig.usuarioTrocarSenhaEndpoint}/reenviar-senha/${usuarioId}`)
  }  

  // Compositores
  usuariosCompositoresLista(...args) {
    return this.axiosIns.get(this.jwtConfig.usuariosCompositoresListaEndpoint, { params: args[0] })
  }
  
  usuariosCompositorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuariosCompositorEditarEndpoint, { params: args[0] })
  }
  
  usuarioCompositorId(...args) {
    const usuarioId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.usuarioCompositorEndpoint}/${usuarioId}`, ...args)
  }
  
  usuarioCompositorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioCompositorEditarEndpoint, ...args)
  }
  
  usuarioCompositorSaldoEditar(...args) {
    return this.axiosIns.patch(this.jwtConfig.usuarioCompositorSaldoEditarEndpoint, ...args)
  }
  
  usuarioCompositorSaldoHistoricoLista(...args) {
    const usuarioId = args[0].usuarioId
    return this.axiosIns.get(`${this.jwtConfig.usuarioCompositorSaldoHistoricoEndpoint}/${usuarioId}`, { params: args[0] })
  }
  
  usuarioCompositorHitsLista(...args) {
    
    return this.axiosIns.get(this.jwtConfig.usuarioCompositorHitsListaEndpoint, {
      
      params: {
        compositorId: args[0].compositorId,
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }
  
  
  // Compradores
  usuariosCompradoresLista(...args) {
    return this.axiosIns.get(this.jwtConfig.usuariosCompradoresListaEndpoint, { params: args[0] })
  }

  usuariosCompradorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuariosCompradorEditarEndpoint, { params: args[0] })
  }

  usuarioCompradorId(...args) {
    const usuarioId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.usuarioCompradorEndpoint}/${usuarioId}`, ...args)
  }

  usuarioCompradorCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.usuarioCompradorCadastrarEndpoint, ...args)
    }
      
  usuarioCompradorEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.usuarioCompradorEditarEndpoint, ...args)
    }

  usuarioCompradorAudicoes(...args) {
    const usuarioId = args[0].compradorId
    return this.axiosIns.get(`${this.jwtConfig.usuarioCompradorAudicoesListaEndpoint}/${usuarioId}`, {
        params: {
          page: args[0].page,
          size: args[0].size,
          sort: args[0].sort,
        },
        paramsSerializer: params => qs.stringify(params, { indices: false }),
      }) 
    }  
  
  // Campanhas
  
  campanha(...args) {
    const campanhaId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.campanhaEndpoint}/${campanhaId}`, ...args)
  }
  
  campanhaLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhaListaEndpoint, ...args)
  }
  
  campanhaPendencias(...args) {

    return this.axiosIns.get(this.jwtConfig.campanhaPendenciasEndpoint, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  campanhaCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.campanhaCadastrarEndpoint, ...args)
  }

  campanhaEditar(...args) {
    return this.axiosIns.put(this.jwtConfig.campanhaEditarEndpoint, ...args)
  }

  campanhaCapaUpload(...args) {
    const { campanhaId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.campanhaCapaUploadEndpoint}/${campanhaId}`, ...args)
  }

  campanhaCapaEditarUpload(...args) {
    const { campanhaId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.campanhaCapaEditarUploadEndpoint}/${campanhaId}`, ...args)
  }
  

  campanhaCompradorId(...args) {
    const campanhaId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.campanhaCompradorIdEndpoint}/${campanhaId}`)
  }

  
  campanhaCompradorHitsId(...args) {

    const campanhaId = args[0].id

    return this.axiosIns.get(`${this.jwtConfig.campanhaCompositorHitsIdEndpoint}/${campanhaId}`, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })

  }

  campanhaQtdHitsPorCampanha(...args) {
    const campanhaId = args[0].campanhaId
    return this.axiosIns.get(`${this.jwtConfig.campanhasQtdHitsPorCampanhaEndpoint}/${campanhaId}`)
  }

  campanhaListaHitsPorCampanha(...args) {
    const campanhaId = args[0].campanhaId
    return this.axiosIns.get(`${this.jwtConfig.campanhasListaHitsPorCampanhaEndpoint}/${campanhaId}`, {
      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })   
  }

  // Campanhas - Compositor

  campanhaCompositorLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhaCompositorListaEndpoint, ...args)
  }

  campanhaTodosCompositorLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhaTodosCompositorListaEndpoint, ...args)
  }

  campanhasAtivasCompositorLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhasAtivasCompositorListaEndpoint, ...args)
  }

  campanhasProximasCompositorLista(...args) {
    return this.axiosIns.get(this.jwtConfig.campanhasProximasCompositorListaEndpoint, ...args)
  }

  campanhaCompositorId(...args) {
    const campanhaId = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.campanhaCompositorIdEndpoint}/${campanhaId}`)
  }

  // Propostas - Compositor/Comprador

  propostasLista(...args) {

    return this.axiosIns.get(this.jwtConfig.propostasListaEndpoint, {

      params: {
        page: args[0].page,
        size: args[0].size,
        sort: args[0].sort,
        listaStatusNegociacao: args[0].listaStatusNegociacao
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
  }

  negociacoesLista(...args) {
    const { propostaId } = args[0]
    return this.axiosIns.get(`${this.jwtConfig.negociacoesListaEndpoint}/${propostaId}`)
  }

  negociacaoCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.negociacaoCadastrarEndpoint, ...args)
  }

  negociacaoPropostaFinalizar(...args) {
    return this.axiosIns.post(this.jwtConfig.negociacaoPropostaFinalizarEndpoint, ...args)
  }

  negociacaoPropostaNegociar(...args) {
    return this.axiosIns.post(this.jwtConfig.negociacaoPropostaNegociarEndpoint, ...args)
  }

  negociacaoPropostaUpgradeExclusividade(...args) {
    return this.axiosIns.post(this.jwtConfig.negociacaoPropostaUpgradeEndpoint, ...args)
  }

  // Configurações

  hitTemaCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.hitTemaCadastrarEndpoint, ...args)
  }

  hitTemaCadastrarLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitTemaCadastrarListaEndpoint, { params: args[0] })
  }

  hitEstiloCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.hitEstiloCadastrarEndpoint, ...args)
  }

  hitEstiloCadastrarLista(...args) {
    return this.axiosIns.get(this.jwtConfig.hitEstiloCadastrarListaEndpoint, { params: args[0] })
  }

  // Gestao

  gestaoNegociacaoListar(...args) {
    return this.axiosIns.get(this.jwtConfig.gestaoNegociacaoListarEndpoint, { params: args[0] })
  }

  gestaoNegociacaoDetalhe(...args) {
    const { propostaId } = args[0]
    return this.axiosIns.get(`${this.jwtConfig.gestaoNegociacaoDetalheEndpoint}/${propostaId}`)
  }

  gestaoNegociacaoUploadDoc(...args) {
    const { propostaId } = args[0]
    return this.axiosIns.post(`${this.jwtConfig.gestaoNegociacaoExcluirDocEndpoint}/${propostaId}`, ...args)
  }

  gestaoNegociacaoExcluirDoc(...args) {
    const { propostaId } = args[0]
    return this.axiosIns.delete(`${this.jwtConfig.gestaoNegociacaoExcluirDocEndpoint}/${propostaId}`)
  }

  gestaoNegociacaoAtualizarStatus(...args) {
    const propostaId = args[0].propostaId
    const dto = args[0].dto
    return this.axiosIns.put(`${this.jwtConfig.gestaoNegociacaoAtualizarStatusEndpoint}/${propostaId}`, dto)
  }
  
  gestaoBuscarTodosHits(...args) {
    return this.axiosIns.get(this.jwtConfig.gestaoBuscarTodosHitsEndpoint, ...args)
  }

  gestaoBuscarTodosCompradores(...args) {
    return this.axiosIns.get(this.jwtConfig.gestaoBuscarTodosCompradoresEndpoint, ...args)
  }

  gestaoBuscarTodosCompositores(...args) {
    return this.axiosIns.get(this.jwtConfig.gestaoBuscarTodosCompositoresEndpoint, ...args)
  }

  // Assinatura

  assinaturaPlanosLista(...args) {
    return this.axiosIns.get(this.jwtConfig.assinaturaPlanosListaEndpoint, ...args)
  }

  assinaturaCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.assinaturaCadastrarEndpoint, ...args)
  }

  assinaturaCancelar(...args) {
    const assinaturaId = args[0].id
    return this.axiosIns.post(`${this.jwtConfig.assinaturaCancelarEndpoint}/${assinaturaId}`)
  }

  // Recarregar Saldo - Pix

  assinaturaPixGerar(...args) {
    const planoId = args[0].id
    return this.axiosIns.post(`${this.jwtConfig.assinaturaPixGerarEndpoint}/${planoId}`)
  }

  assinaturaPixStatusPagamento(...args) {
    return this.axiosIns.get(this.jwtConfig.assinaturaPixStatusPagamentoEndpoint, ...args)
  }

  // Compositor - Endereço

  compositorEndereco(...args) {
    return this.axiosIns.post(this.jwtConfig.compositorEnderecoEndpoint, ...args)
  }

  compositorAssinatura(...args) {
    return this.axiosIns.get(this.jwtConfig.compositorAssinaturaEndpoint, ...args)
  }

  // Perfil
  perfilListar(...args) {
    return this.axiosIns.get(this.jwtConfig.hitPerfilCadastrarEndpoint, ...args)
  }

  perfilId(...args) {
    const id = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.hitPerfilCadastrarEndpoint}/${id}`)
  }

  perfilAdicionarAbility(...args) {
    return this.axiosIns.post(this.jwtConfig.hitPerfilAdicionarAbilityEndpoint, ...args);
  }

  perfilRemoverAbility(...args) {
    const perfilId = args[0].perfilId
    const abilityId = args[0].abilityId
    return this.axiosIns.delete(`${this.jwtConfig.hitPerfilPerfilAbilityEndpoint}/${perfilId}/${abilityId}`);
  }

  // Ability
  abilityListar(...args) {
    return this.axiosIns.get(this.jwtConfig.abilityListaEndpoint, { params: args[0] })
  }

  abilityListarTotal(...args) {
    return this.axiosIns.get(this.jwtConfig.abilityListaTotalEndpoint, { params: args[0] })
  }

  abilityListarId(...args) {
    const id = args[0].id
    return this.axiosIns.get(`${this.jwtConfig.abilityListaEndpoint}/${id}`)
  }

  abilityCadastrar(...args) {
    return this.axiosIns.post(this.jwtConfig.abilityListaEndpoint, ...args)
  }

  abilityRemover(...args) {
    const abilityId = args[0].abilityId
    return this.axiosIns.delete(`${this.jwtConfig.abilityListaEndpoint}/${abilityId}`);
  }
  
  // Parametro
  listarParametros(...args) {
    return this.axiosIns.get(this.jwtConfig.listarParametrosEndpoint, { params: args[0] })
  }

  alterarSituacaoParametros(...args) {
    const id = args[0].id
    return this.axiosIns.put(`${this.jwtConfig.alterarSituacaoParametrosEndpoint}/${id}`)
  }

  alterarDadosParametros(...args) {
    const id = args[0].id
    const dto = args[0].dto
    return this.axiosIns.put(`${this.jwtConfig.alterarDadosParametrosEndpoint}/${id}`, dto)
  }
  
}
