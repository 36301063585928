export default [

  {
    path: '/conta/configuracoes',
    name: 'conta-configuracoes',
    component: () => import('@/views/pages/conta-configuracoes/ContaConfiguracao.vue'),
    meta: {
      layout: 'read',
      resource: 'Auth',
      pageTitle: 'Configurações',
      breadcrumb: [
        {
          text: 'Conta',
        },
        {
          text: 'Configurações',
          active: true,
        },
      ],
    },
  },

]
