export default [

  {
    path: '/mercado/hits',
    name: 'hit-gestor',
    component: () => import('@/views/pages/hits/HitGestaoLista.vue'),
    meta: {
      pageTitle: 'Mercado',
      resource: 'MercadoGestor',
      action: 'read',
      breadcrumb: [
        {
          text: 'Mercado',
          active: true,
        },
      ],
    },
  },

]
