export default [

  {
    path: '/gestao/negociacao',
    name: 'gestao-negociacao',
    component: () => import('@/views/pages/gestao/negociacao/GestaoNegociacaoLista.vue'),
    meta: {
      pageTitle: 'Negociação',
      resource: 'GestaoNegociacao',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negociação ',
        },
        {
          text: 'Gestao',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gestao/negociacao/:id',
    name: 'gestao-negociacao-detalhe',
    component: () => import('@/views/pages/gestao/negociacao/GestaoNegociacaoDetalhe.vue'),
    meta: {
      pageTitle: 'Detalhe da negociação',
      resource: 'GestaoNegociacao',
      action: 'read',
      breadcrumb: [
        {
          text: 'Negociação ',
          to: '/gestao/negociacao'
        },
        {
          text: 'Gestao',
          to: '/gestao/negociacao'
        },
        {
          text: 'Detalhe da negociação',
          active: true,
        },
      ],
    },
  },

]
