export default [

  { 
    path: '/aprovacoes/hit',
    name: 'aprovacoes-hit',
    component: () => import('@/views/pages/aprovacoes/hit/AprovacaoLista.vue'),
    meta: {
      resource: 'AprovacaoHit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Hits',
        },
      ],
    },
  },

  {
    path: '/aprovacoes/hit/editar/:id',
    name: 'aprovacoes-hit-editar',
    component: () => import('@/views/pages/aprovacoes/hit/aprovacoes-editar/AprovacaoEditar.vue'),
    meta: {
      pageTitle: 'Aprovar Hit',
      resource: 'AprovacaoHit',
      action: 'read',
      breadcrumb: [
        {
          text: 'Aprovações',
          to: '/aprovacoes/hit',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/aprovacoes/campanha',
    name: 'aprovacoes-campanha',
    component: () => import('@/views/pages/aprovacoes/campanha/AprovacaoLista.vue'),
    meta: {
      pageTitle: 'Aprovação de Campanha',
      resource: 'AprovacaoCampanha',
      action: 'read',
      breadcrumb: [
        {
          text: 'Campanhas',
        },
      ],
    },
  },

  {
    path: '/aprovacoes/campanha/editar/:id',
    name: 'aprovacoes-campanha-editar',
    component: () => import('@/views/pages/aprovacoes/campanha/aprovacoes-editar/AprovacaoEditar.vue'),
    meta: {
      pageTitle: 'Aprovar Campanha',
      resource: 'AprovacaoCampanha',
      action: 'read',
      breadcrumb: [
        {
          text: 'Aprovações',
          to: '/aprovacoes/campanha',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },

]
