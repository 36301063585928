export default [

  {
    path: '/financeiro/saldo',
    name: 'financeiro-saldo',
    component: () => import('@/views/pages/financeiro/Saldo.vue'),
    meta: {
      pageTitle: 'Saldo',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Saldo',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/recarregar',
    name: 'financeiro-recarregar',
    component: () => import('@/views/pages/financeiro/SaldoRecarregar.vue'),
    meta: {
      pageTitle: 'Recarregar',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Recarregar',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/assinatura/planos',
    name: 'financeiro-assinatura-planos',
    component: () => import('@/views/pages/financeiro/SaldoAssinaturaPlanos.vue'),
    meta: {
      pageTitle: 'Planos',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Recarregar',
          to: '/financeiro/assinatura/planos',
          // to: '/financeiro/recarregar',
        },
        {
          text: 'Planos',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/assinatura/recarregar/:id',
    name: 'financeiro-assinatura-recarregar',
    component: () => import('@/views/pages/financeiro/SaldoAssinaturaRecarregar.vue'),
    meta: {
      pageTitle: 'Assinatura',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Recarregar',
          to: '/financeiro/assinatura/planos',
          // to: '/financeiro/recarregar',
        },
        {
          text: 'Planos',
          to: '/financeiro/assinatura/planos',
        },
        {
          text: 'Assinatura',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/recarregar/planos',
    name: 'financeiro-recarregar-planos',
    component: () => import('@/views/pages/financeiro/SaldoRecarregarPlanos.vue'),
    meta: {
      pageTitle: 'Créditos',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Recarregar',
          to: '/financeiro/assinatura/planos',
          // to: '/financeiro/recarregar',
        },
        {
          text: 'Créditos',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/recarregar/credito',
    name: 'financeiro-recarregar-credito',
    component: () => import('@/views/pages/financeiro/SaldoRecarregar.vue'),
    meta: {
      pageTitle: 'Crédito',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Saldo',
          active: true,
        },
      ],
    },
  },

  {
    path: '/financeiro/recarregar/:id',
    name: 'financeiro-recarregar-credito-id',
    component: () => import('@/views/pages/financeiro/SaldoRecarregarCredito.vue'),
    meta: {
      pageTitle: 'Recarregar',
      resource: 'SaldoFinanceiro',
      action: 'read',
      breadcrumb: [
        {
          text: 'Financeiro',
          to: '/financeiro/saldo',
        },
        {
          text: 'Recarregar',
          // to: '/financeiro/recarregar',
          to: '/financeiro/assinatura/planos',
        },
        {
          text: 'Planos',
          to: '/financeiro/assinatura/planos',
        },
        {
          text: 'Recarregar',
          active: true,
        },
      ],
    },
  },

]
