export default [

  {
    path: '/hit/cadastrar',
    name: 'hit-cadastrar',
    component: () => import('@/views/pages/hits/HitCadastrar.vue'),
    meta: {
      pageTitle: 'Enviar Hit',
      resource: 'HitCadastrar',
      action: 'read',
      breadcrumb: [
        {
          text: 'Hit',
        },
        {
          text: 'Enviar Hit',
          active: true,
        },
      ],
    },
  },

  {
    path: '/hit/lista',
    name: 'hit-lista',
    component: () => import('@/views/pages/hits/HitCompositorLista.vue'),
    meta: {
      pageTitle: 'Meus Hits',
      resource: 'HitLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Hit',
        },
        {
          text: 'Meus Hits',
          active: true,
        },
      ],
    },
  },

  {
    path: '/hit/pendencias',
    name: 'hit-pendencias',
    component: () => import('@/views/pages/hits/pendencias/HitCompositorPendencias.vue'),
    meta: {
      pageTitle: 'Pendências',
      resource: 'HitLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Hit',
        },
        {
          text: 'Pendências',
          active: true,
        },
      ],
    },
  },

  {
    path: '/hit/editar/:id',
    name: 'hit-editar',
    component: () => import('@/views/pages/hits/editar/HitCompositorEditar2.vue'),
    meta: {
      pageTitle: 'Editar Hit',
      resource: 'HitLista',
      action: 'read',
      breadcrumb: [
        {
          text: 'Hit',
        },
        {
          text: 'Editar Hit',
          active: true,
        },
      ],
    },
  },


]
